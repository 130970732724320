import React from 'react'
import '../styles/Navbar.css'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from 'react-scroll';
import discord from '../images/DISCORD1.png'
import twitter from '../images/TWITTER1.png'
import opensea from '../images/OPENSEA1.png'
import whitepaper from '../images/WHITEPAPER1.png'
import ConnectBtn from '../components/ConnectButton'

export const PlanetNavbar = () => {

  const handleClick = (e) => {
    e.preventDefault();
    console.log('The wallet connect link was clicked.');
  }
 

  return (
    <Navbar className='navbar-style' collapseOnSelect expand="lg">
    <Container>
      <Navbar.Toggle className='navbar-toggler' aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav" >
        <Nav className="me-auto linkset-one">
        <Link
            activeClass="active"
            to="info"
            spy={true}
            smooth={true}
            duration={500}
            offset={300}
          >
        <Nav.Link className="nav-links" active="false" >Lore</Nav.Link>
        </Link>
        <Link
            activeClass="active"
            to="utility"
            spy={true}
            smooth={true}
            duration={500}
            offset={-300}
          >
          <Nav.Link className="nav-links" active="false" >Utility</Nav.Link>
          </Link>
          <Link
            activeClass="active"
            to="utility"
            spy={true}
            smooth={true}
            duration={500}
            offset={600}
          >
          <Nav.Link className="nav-links" active="false" >Team</Nav.Link>
          </Link>
          <Link
            activeClass="active"
            to="faq"
            spy={true}
            smooth={true}
            duration={500}
          >
          <Nav.Link className="nav-links " >FAQ</Nav.Link>
          </Link>
        </Nav>
        <div className='socials'>
          <a href="https://discord.gg/planetdegen" target="_blank" rel=" noopener noreferrer" className='social-a'>
            <img className='socials-img' src={discord} alt='discord'/>
            </a>
          <a href="https://twitter.com/PlanetDegenNFT?s=20" target="_blank" rel=" noopener noreferrer" className='social-a'>
          <img className='socials-img' src={twitter} alt='twitter'/>
          </a>
          <a href="https://opensea.io/collection/planetdegennft" target="_blank" rel=" noopener noreferrer" className='social-a'>
          <img className='socials-img' src={opensea} alt='opensea'/>
          </a>
          <a href="https://planet-degen-whitepaper.gitbook.io/planet-degen-whitepaper/about-planet-degen/vision" target="_blank" rel=" noopener noreferrer" className='social-a'>
          <img className='socials-img' src={whitepaper} alt='whitepaper'/>
          </a>
        </div>
        <Nav className="me-auto linkset-two">
        <ConnectBtn />
        </Nav>
      </Navbar.Collapse>
    </Container>
  </Navbar>
  )
}

