import React from 'react'
import '../styles/components.css'
import LoreCarousel from './LoreCarousel';
import AOS from 'aos';
import { useEffect } from 'react';
import helmet from '../images/HELM.png';


export const LoreContainer = () => {

  useEffect(() => {
    AOS.init({
      duration : 2000
    });
  }, []);

    const SlideData = [
        {
          title: "Log entry no. 1, star date 3333:",
          text:
            "Planet Degen was once a utopian paradise, blessed with lush vegetation, crystal-clear waters, and a vibrant ecosystem. Its inhabitants were peaceful and prosperous, and the planet had become the largest source of trade and commerce for the entire universe. However, this all changed when a terrible truth was uncovered, threatening the safety and security of the entire planet.",
        },
        {
            title: "Log entry no. 2, star date 3333:",
            text:
            "The protagonist of our story is the prince of Planet Degen, and the leader of the Golden Gauntlets, an elite group of warriors dedicated to protecting their planet. One day a coup had taken place that led him on a false mission away from his home, banished and deserted to wander the universe alone, but his desire to uncover the truth and restore peace to his people never faltered.",
        },
        {
            title: "Log entry no. 3, star date 3333:",
            text:
            "Months went by, and the prince had honed his skills and gathered allies from all corners of the universe. With a heart full of determination and a mind full of strategy, he set out to return to Planet Degen and bring those who had done wrong to justice. It was a perilous journey, but the prince was unwavering in his resolve. He knew that the fate of his planet, and perhaps the fate of the entire universe, depended on his success.",
        }
      ];
  return (
      <div className='lore-container container-fluid' data-aos="fade-up">
        <div className='carousel'>
          <h1 className='origins-header' >Origins</h1>
          <LoreCarousel slides={SlideData} />
        </div>
        
        <div className='helmet-container'>
          <img data-aos="fade-down" alt='mint' className='helmet' src={helmet} />
        </div>
        
      </div>
  )
}



