import React, { useState } from "react";
import { LoreSlides, StyledSlider } from "./LoreSlides";
import '../styles/components.css'
import {
  FaChevronRight,
  FaChevronLeft,
} from "react-icons/fa";

const LoreCarousel = ({ slides }) => {
    const [current, setCurrent] = useState(0);
    const length = slides.length;
  
    const nextSlide = () => {
      setCurrent(current === length - 1 ? 0 : current + 1);
    };
  
    const prevSlide = () => {
      setCurrent(current === 0 ? length - 1 : current - 1);
    };
  
    return (
      <StyledSlider style={{textAlign: 'center'}}>

        {slides.map((slide, index) => {
          return (
            <div key={index}>
              {index === current && (
                <LoreSlides text={slide.text} title={slide.title}/>
              )}
            </div>
          );
        })}
        <FaChevronLeft style={{margin: '1rem 2rem 0 1rem', cursor: 'pointer', fontSize: 'xx-large'}}
          className="left-arrow"
          onClick={prevSlide}
        />
        <FaChevronRight style={{margin: '1rem 1rem 0 2rem', cursor: 'pointer', fontSize: 'xx-large'}}
          className="right-arrow"
          onClick={nextSlide}
        />
      </StyledSlider>
    );
  };


export default LoreCarousel
