import React from 'react'
import '../styles/Navbar.css'
import { ConnectButton } from '@rainbow-me/rainbowkit';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from 'react-scroll';




export default function ConnectBtn(){




return (
    <ConnectButton.Custom>
      {({
        account,
        chain,
        openAccountModal,
        openChainModal,
        openConnectModal,
        authenticationStatus,
        mounted,
      }) => {
        // Note: If your app doesn't use authentication, you
        // can remove all 'authenticationStatus' checks
        const ready = mounted && authenticationStatus !== 'loading';
        const connected =
          ready &&
          account &&
          chain &&
          (!authenticationStatus ||
            authenticationStatus === 'authenticated');

        return (
          <div
            {...(!ready && {
              'aria-hidden': true,
              'style': {
                opacity: 0,
                pointerEvents: 'none',
                userSelect: 'none',
              },
            })}
          >
            {(() => {
              if (!connected) {
                return (
                    <Nav.Link className="connect-wallet" style={{color: 'white'}}  onClick={openConnectModal}>
                        Wallet Connect
                    </Nav.Link>


                );
              }

              if (chain.unsupported) {
                return (
                    <Nav.Link className="connect-wallet" style={{color: 'white'}}  onClick={openChainModal}>
                    Change Network
                </Nav.Link>

                );
              }

              return (
                <div style={{ display: 'flex', gap: 12, textAlign: 'center'}}>
                    <Nav.Link className="connect-wallet" style={{color: 'white', marginLeft: '7rem'}}  onClick={openAccountModal}>
                    {account.displayName}
                    </Nav.Link>
                   
                </div>
              );
            })()}
          </div>
        );
      }}
    </ConnectButton.Custom>
  );
};