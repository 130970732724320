import React, { useState, useEffect } from 'react'
import { getDatabase, onValue, ref } from "firebase/database";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {  configureChains, useAccount, usePrepareContractWrite, useContractWrite, useContractRead  } from 'wagmi'


const firebaseConfig = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_DATABASEURL,
  projectId: process.env.REACT_APP_PROJECTID,
  storageBucket: process.env.REACT_APP_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_APPID,
  measurementId: process.env.REACT_APP_MEASUREMENTID
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getDatabase(app);

const whitelists = ["wl"];
const oglists = ["og"]
let testing = false


function parseError(error) {
  let content = error.toString();
  if (content.startsWith('Error:')) {
    content = content.substring('Error:'.length);
    content = content.split('[ See:')[0]
  }

  return content
}

async function getWhitelistStatus(account) {
  if (!account) {
    return undefined;
  }
  account = account.toLowerCase();

  return await new Promise((resolve, reject) => {
    const refs = [];
    const ran = [];
    const signatures = [];
    const prefix = testing ? "test/" : "";

    for (let i = 0; i < whitelists.length; i++) {
      refs.push(ref(db, `${whitelists[i]}/${account}/`));
      signatures.push(undefined);
    }

    
    for (let i = 0; i < whitelists.length; i++) {
      const tmpI = i;
      onValue(refs[tmpI], (snapshot) => {
        signatures[tmpI] = snapshot.val();
        ran.push(true);
        validateOutput();
      });
    }

    function validateOutput() {
      
      if (ran.length === refs.length) {
        const finalArr = [];

        for (let i = 0; i < whitelists.length; i++) {
          
          const signature = signatures[i];
          const whitelist = whitelists[i];
          if (signature === undefined || signature === null) {
            
            finalArr.push(null);
            continue;
          }

          finalArr.push({
            signature,
            whitelist,
          });
        }

        resolve(finalArr);
      }
    }
  });
}

async function getOglistStatus(account) {
  if (!account) {
    return undefined;
  }
  account = account.toLowerCase();
  return await new Promise((resolve, reject) => {
    const refs = [];
    const ran = [];
    const signatures = [];
    const prefix = testing ? "test/" : "";
    
    for (let i = 0; i < oglists.length; i++) {
      refs.push(ref(db, `${oglists[i]}/${account}/`));
      signatures.push(undefined);
    }

    
    for (let i = 0; i < oglists.length; i++) {
      const tmpI = i;
      onValue(refs[tmpI], (snapshot) => {
        signatures[tmpI] = snapshot.val();
        ran.push(true);
        validateOutput();
      });
    }

    function validateOutput() {
      
      if (ran.length === refs.length) {
        const finalArr = [];

        for (let i = 0; i < oglists.length; i++) {
          
          const signature = signatures[i];
          const whitelist = oglists[i];
          if (signature === undefined || signature === null) {
            
            finalArr.push(null);
            continue;
          }

          finalArr.push({
            signature,
            whitelist,
          });
        }

        resolve(finalArr);
      }
    }
  });
}

export default function useDbInfo(){

const [isWhitelisted, setWLStatus] = useState(false);
const [isOGlisted, setOGStatus] = useState(false);
const [WhiteListSig, setWLSig] = useState();
const [OGListSig, setOGSig] = useState();
const { address, connector, isConnected } = useAccount()


  
useEffect(() => {
    if (!isConnected) {
      return;
    }

    checkDb(address)
      .then((data) => {
        
        setWLStatus(data.wlStatus ?? false);
        setOGStatus(data.ogStatus ?? false);

        setWLSig(data.wlSignature);
        setOGSig(data.ogSignature);
      })
      .catch((err) => console.error(err));
  }, []);

  return [isWhitelisted, isOGlisted, WhiteListSig, OGListSig];
}




async function checkDb(address) {
  const data = {};

const databaseStatusWL = await getWhitelistStatus(address);
if (databaseStatusWL[0]) {
data.wlSignature = databaseStatusWL[0].signature;

if (databaseStatusWL[0].whitelist === "wl") {
  data.wlStatus = true;
}
}

const databaseStatusOG = await getOglistStatus(address);
if (databaseStatusOG[0]) {
data.ogSignature = databaseStatusOG[0].signature;

if (databaseStatusOG[0].whitelist === "og") {
  data.ogStatus = true;
}
}

return data;
}
