import React from 'react';
import '../styles/Faq.css';
import 'bootstrap'
import { Link } from 'react-router-dom';
import AOS from 'aos';
import { useEffect } from 'react';

export const Faq = () => {

  useEffect(() => {
    AOS.init({
      duration : 2000
    });
  }, []);

  return (
    <div className='faq' data-aos="fade-up">
      <div className='container-fluid'>
            <h1 className ='faq-header' >FAQ</h1>
              <div className='container' >
                <div className="accordion accordion-flush" id="accordionFlushExample" style={{margin:'6rem 8rem 2rem 8rem'}}>
                
                <div data-aos="fade-up"  className="accordion-item col-xs-12 what-tab" style={{ background: 'rgba(255,255,255,0.5)', color:'white', border: '1px solid black', borderRadius:'5px'}}>
                  <h2 className="accordion-header" id="flush-headingOne">
                    <button className="accordion-button collapsed font-weight-bold cursor button-text" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne" style={{color:'white', borderRadius:'5px'}}>
                    WHAT IS PLANET DEGEN?
                      <span class="glyphicon glyphicon-minus"></span>
                    </button>
                  </h2>
                  <div id="flush-collapseOne" className="accordion-collapse collapse font-weight-bold" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                    <div className="accordion-body" style={{fontFamily: 'zekton', fontSize: '16px'}}>Planet Degen is an animated NFT collection, providing tools, alpha, education and giveaways.</div>
                  </div>
                </div>

                <div data-aos="fade-up"  className="accordion-item col-xs-12 what-tab " style={{ background: 'rgba(255,255,255,0.5)', color:'white', border: '1px solid black', borderRadius:'5px'}}>
                  <h2 className="accordion-header" id="flush-headingTwo">
                    <button className="accordion-button collapsed font-weight-bold" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo" style={{color:'white', borderRadius:'5px'}}>
                    HOW MANY NFTS DOES THE COLLECTION CONTAIN?
                    </button>
                  </h2>
                  <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                    <div className="accordion-body font-weight-bold" style={{fontFamily: 'zekton', fontSize: '16px'}}>3333</div>
                  </div>
                </div>

                <div data-aos="fade-up"  className="accordion-item col-xs-12 what-tab" style={{ background: 'rgba(255,255,255,0.5)', color:'white', border: '1px solid black', borderRadius:'5px'}}>
                  <h2 className="accordion-header" id="flush-headingThree">
                    <button className="accordion-button collapsed font-weight-bold" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree" style={{color:'white', borderRadius:'5px'}}>
                    WHAT IS THE PRICE OF THE NFT?
                    </button>
                  </h2>
                  <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                    <div className="accordion-body font-weight-bold" style={{fontFamily: 'zekton', fontSize: '16px'}}>0.019 ETH  </div>
                  </div>
                </div>

                <div data-aos="fade-up"  className="accordion-item col-xs-12 what-tab " style={{ background: 'rgba(255,255,255,0.5)', border: '1px solid black', color:'white', borderRadius:'5px'}}>
                  <h2 className="accordion-header" id="flush-headingFour">
                    <button className="accordion-button collapsed font-weight-bold" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour" style={{color:'white', borderRadius:'5px'}}>
                    WHEN IS THE MINT?
                    </button>
                  </h2>
                  <div id="flush-collapseFour" className="accordion-collapse collapse" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">
                    <div className="accordion-body font-weight-bold" style={{fontFamily: 'zekton', fontSize: '16px'}}>Mint is scheduled for March 2023. Keep an eye on announcements in discord or twitter for further details.</div>
                  </div>
                </div>

                <div data-aos="fade-up"  className="accordion-item col-xs-8" style={{ background: 'rgba(255,255,255,0.5)', color:'white', border: '1px solid black', borderRadius:'5px'}}>
                  <h2 className="accordion-header" id="flush-headingFive">
                    <button className="accordion-button collapsed font-weight-bold" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive" style={{color:'white', borderRadius:'5px'}}>
                     HOW CAN I GET WHITELISTED (WL)?
                    </button>
                  </h2>
                  <div id="flush-collapseFive" className="accordion-collapse collapse" aria-labelledby="flush-headingFive" data-bs-parent="#accordionFlushExample">
                    <div className="accordion-body font-weight-bold" style={{fontFamily: 'zekton', fontSize: '16px'}}>WL can be obtained through collab giveaways or by participating in events in our discord. 
                    </div>
                  </div>
                </div>

                <div data-aos="fade-up"  className="accordion-item col-xs-8" style={{ background: 'rgba(255,255,255,0.5)', color:'white', border: '1px solid black', borderRadius:'5px'}}>
                  <h2 className="accordion-header" id="flush-headingSix">
                    <button className="accordion-button collapsed font-weight-bold" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSix" aria-expanded="false" aria-controls="flush-collapseSix" style={{color:'white', borderRadius:'5px'}}>
                    HOW WILL THE MINT/ROYALTY FUNDS BE USED?
                    </button>
                  </h2>
                  <div id="flush-collapseSix" className="accordion-collapse collapse" aria-labelledby="flush-headingSix" data-bs-parent="#accordionFlushExample">
                    <div className="accordion-body font-weight-bold" style={{fontFamily: 'zekton', fontSize: '16px'}}>The proceeds of the mint will go directly to the development of the ecosystem, to provide utility/value for Planet Degen holders. <br/> Planet Degen will receive 6.9% in royalties from NFT marketplaces such as Open Sea and Looks Rare. This will go towards paying staff salaries and monthly upkeep of tools for holders.
                    </div>
                  </div>
                </div>
                
                <div data-aos="fade-up"  className="accordion-item col-xs-8" style={{ background: 'rgba(255,255,255,0.5)', color:'white', border: '1px solid black', borderRadius:'5px'}}>
                  <h2 className="accordion-header" id="flush-headingSeven">
                    <button className="accordion-button collapsed font-weight-bold" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSeven" aria-expanded="false" aria-controls="flush-collapseSeven" style={{color:'white', borderRadius:'5px'}}>
                    HOW CAN I CONTACT PLANET DEGEN?
                    </button>
                  </h2>
                  <div id="flush-collapseSeven" className="accordion-collapse collapse" aria-labelledby="flush-headingSeven" data-bs-parent="#accordionFlushExample">
                    <div className="accordion-body font-weight-bold" style={{fontFamily: 'zekton', fontSize: '16px'}}>Please open a ticket in our discord or send a message to the project twitter to contact us.
                    </div>
                  </div>
                </div>
              </div>
              </div>
          </div>
    </div>
   
    
  )
}




