import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import MINTPASSIVE from '../images/MINT_BUTTON1.png'
import MINTACTIVE from '../images/MINT_BUTTON2.png'
import PLUS from '../images/PLUS.png'
import MINUS from '../images/MINUS.png'
import '../styles/components.css'
import MINTANIMATEPASSIVE from '../gif/MINT-PAGE-PASSIVE.gif'
import MINTANIMATEACTIVE from '../gif/MINT-PAGE-ACTIVE.gif'
import {  configureChains, useAccount, usePrepareContractWrite, useContractWrite, useContractRead  } from 'wagmi'
import ABI from "../ABI/abi.json"
import { getDatabase, onValue, ref } from "firebase/database";
import { initializeApp } from "firebase/app";
import { ethers } from "ethers";
import { Network, Alchemy } from "alchemy-sdk";
import useDbInfo from "./useDbInfo"

const settings = {
  apiKey: process.env.REACT_APP_ALCHEMY_ID, // Replace with your Alchemy API Key.
  network: Network.ETH_MAINNET, // Replace with your network.
};
const alchemy = new Alchemy(settings);



export function Mint() {
  const [isWhitelisted, setWLStatus] = useState();
  const [isOGlisted, setOGStatus] = useState();
  const [WhiteListSig, setWLSig] = useState();
  const [OGListSig, setOGSig] = useState();
  const contract = process.env.REACT_APP_CONTRACT_ADDRESS

  const [count, setCount] = useState(1);
  const [overMint, setOverMint] = useState(false)
  const [mintButtonClicked, setMintButtonClicked] = useState(false);
  const { address, isConnecting, isDisconnected, isConnected } = useAccount()

  const wei = ethers.utils.parseEther(
    (0.019 * count).toString()
  );
  const gasLimit = `${120000 * count}`;
  const trx = {
    nonce: (alchemy.core.getTransactionCount(address)) || undefined,
    gasLimit: gasLimit,
    value: wei,
  };  

  const { config, error, data } = usePrepareContractWrite({
    address: process.env.REACT_APP_CONTRACT_ADDRESS,
    abi: ABI,
    functionName: 'mint',
  args: [count,trx ]
  })


  const { write } = useContractWrite(config)


  const incrementCount = () => {
    if (count < 2) {
      setCount(count + 1);
    }
    else {
      return;
    }
    
  };

  const decrementCount = () => {
    if (count > 1) {
      setCount(count - 1);
    }
    else {
      return;
    }
  };

async function twasclicked(){
  setMintButtonClicked(true)
}



  return (
    <div className='mint-container' style={{ backgroundImage: mintButtonClicked ? `url(${MINTANIMATEACTIVE})` : `url(${MINTANIMATEPASSIVE})`}}>
      <div className='container-fluid d-flex justify-content-center mt-3'>
      <Button href='/' className='home-button'>HOME</Button>
      <p className='counter' >{count}</p>
      </div>
      <div  className='mint-buttons justify-content-center'>

        <div className='mint-popup' style={{display: 'flex', flexDirection: 'row'}}>

          <Button className='mint-counter' ><img  className='mint-button' alt='minus'
              onMouseOver={() => setOverMint(true)}
              onMouseOut={() => setOverMint(false)}
              src= {overMint ? MINTACTIVE : MINTPASSIVE}/></Button>
                                  
          </div>    
          <div className='mint-counters' style={{display: 'flex', flexDirection: 'row'}}>    
          <Button className='mint-counter' onClick={decrementCount}><img src={MINUS} className='plus-minus' alt='minus'/></Button>{' '}
          <Button className='mint-counter' onClick={incrementCount}><img src={PLUS} className='plus-minus' alt='plus'/></Button>
      </div>
      </div>
    </div>
  );
}

