import React from 'react'
import zaddy from '../gif/llama.gif'
import med from '../gif/Med.gif'
import bamf from '../gif/bamf.gif'
import reykeef from '../gif/reykeef.gif'
import pong from '../gif/pong.gif'
import jmoyer from '../gif/jmoyer.gif'
import twitter from '../images/TWITTER1.png'
import '../styles/components.css'
import AOS from 'aos';
import { useEffect } from 'react';


export const TeamContainer = () => {

    useEffect(() => {
        AOS.init({
          duration : 2000
        });
      }, []);


    


  return (
    <div className='team-container container' data-aos="fade-up">
        
        <h1 className ='team-header' >Team</h1>
        <div className='non-mobile'>
        <div className='team-row'>
            <div className='col team-widget' style={{textAlign: 'center'}}>
            <a className= 'team-links' href="https://twitter.com/Zaddy_NFT" target="_blank" rel=" noopener noreferrer">
                    <img className= 'team-image' src={zaddy} alt='member1'/>
                    <p className= 'team-text' style={{marginTop: '1rem'}}>Founder: Zaddy <span> <img src={twitter} alt='Zaddy twitter' /></span></p>
            </a>
            </div>
            <div className='col team-widget'>
            <a className= 'team-links' href="https://twitter.com/reykeef" target="_blank" rel=" noopener noreferrer">
                    <img src={reykeef} className= 'team-image' alt='member2'/>
                    <p className= 'team-text' style={{marginTop: '1rem'}}>Founder: Reykeef <span><img src={twitter} alt='Reykeef twitter' /></span></p>
            </a>
            </div>
            </div>
            <div className='team-row'>
            <div className='col team-widget'>
            <a className= 'team-links' href="https://twitter.com/Medregeth" target="_blank" rel=" noopener noreferrer">
                    <img src={med} className= 'team-image' alt='member3'/>
                    <p className= 'team-text' style={{marginTop: '1rem'}}>Founder: Medreg <span><img src={twitter} alt='Medreg twitter' /></span></p>
            </a>
            </div>
                <div className='col team-widget'>
                <a className= 'team-links' href="https://twitter.com/0xBamf" target="_blank" rel=" noopener noreferrer">
                    <img src={bamf} className= 'team-image' alt='member4'/>
                    <p className= 'team-text' style={{marginTop: '1rem'}}>Founder: Bamf <span><img src={twitter} alt='Bamf twitter' /></span></p>
                </a>
                </div>
            </div>
            <div className='team-row2'>
                <div className='col team-widget'>
                <a className= 'team-links' href="https://twitter.com/NFT_PONG" target="_blank" rel=" noopener noreferrer" >
                    <img src={pong} className= 'team-image2' alt='member5'/>
                    <p className= 'team-text' style={{marginTop: '1rem'}}>Artist: Pong <span><img src={twitter} alt='Pong twitter' /></span></p>
                </a>
                </div>
                <div className='col team-widget'>
                <a className= 'team-links' href="https://twitter.com/BitMoyer" target="_blank" rel=" noopener noreferrer" >
                    <img src={jmoyer} className= 'team-image2' alt='member5'/>
                    <p className= 'team-text' style={{marginTop: '1rem'}}>Developer: JMoyer <span><img src={twitter} alt='Pong twitter' /></span></p>
                </a>
                </div>
        </div>
            </div>
        <div className='mobile'>
        <div className='team-row2'>
            <div className='col team-col'>
                <a className= 'team-links' href="https://twitter.com/Zaddy_NFT" target="_blank" rel=" noopener noreferrer">
                <img className= 'team-image' src={zaddy} alt='member1'/>
                <p className= 'team-text2' style={{marginTop: '1rem'}}>Founder: Zaddy <span><img src={twitter} alt='Zaddy twitter' /></span></p>
                </a>
            </div>
            <div className='col team-col'>
            <a className= 'team-links' href="https://twitter.com/reykeef" target="_blank" rel=" noopener noreferrer">
                <img src={reykeef} className= 'team-image' alt='member2'/>
                <p className= 'team-text2' style={{marginTop: '1rem'}}>Founder: Reykeef <span><img src={twitter} alt='Reykee twitter' /></span></p>
                </a>
            </div>
        </div>
        <div className='team-row'>
            <div className='col team-col'>
                <img src={med} className= 'team-image' alt='member3'/>
                <a className= 'team-links' href="https://twitter.com/Medregeth" target="_blank" rel=" noopener noreferrer">
                <p className= 'team-text2' style={{marginTop: '1rem'}}>Founder: Medreg <span><img src={twitter} alt='Medreg twitter' /></span></p>
                </a>
            </div>
            <div className='col team-col'>
            <a className= 'team-links' href="https://twitter.com/0xBamf" target="_blank" rel=" noopener noreferrer">
                <img src={bamf} className= 'team-image' alt='member4'/>
                <p className= 'team-text2' style={{marginTop: '1rem'}}>Founder: Bamf <span><img src={twitter} alt='Bamf twitter' /></span></p>
                </a>
            </div>
        </div>
        <div className='team-row'>
            <div className='col team-col'>
            <a className= 'team-links' href="https://twitter.com/NFT_PONG" target="_blank" rel=" noopener noreferrer" >
                <img src={pong} className= 'team-image'  alt='member5'/>
                <p className= 'team-text2' style={{marginTop: '1rem'}}>Artist: Pong <span><img src={twitter} alt='Pong twitter' /></span></p>
                </a>
            </div>
            <div className='col team-col'>
            <a className= 'team-links' href="https://twitter.com/NFT_PONG" target="_blank" rel=" noopener noreferrer" >
                <img src={jmoyer} className= 'team-image'  alt='member5'/>
                <p className= 'team-text2' style={{marginTop: '1rem'}}>Developer: JMoyer <span><img src={twitter} alt='Pong twitter' /></span></p>
                </a>
            </div>
        </div>
        </div>
        
        
    </div>
  )
}

