import React, { useState, useEffect } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { Home } from './pages/Home'
import 'aos/dist/aos.css';
import { Mint } from './assets/components/Mint';



function App() {
 





  document.body.style.backgroundColor = "#201f1f";
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        {/* <Route path="/mint" element={<Mint />} /> */}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
