import React from 'react'
import '../styles/components.css'
import bot from '../images/BOT.png'
import AOS from 'aos';
import { useEffect } from 'react';

export const InfoContainer = () => {

  useEffect(() => {
    AOS.init({
      duration : 2000
    });
  }, []);

  return (
      <div   className='info-container container-fluid' data-aos="fade-up">
        <div data-aos="fade-down" className='col bot-container' style={{padding: '0'}}>
          <img data-aos="fade-down" alt='mint' className='bot' src={bot} />
        </div>
        <div className='col info-section'>
        <h1 className='overview-header' >Planet Degen Overview</h1>
        <h4 className='info'>Planet Degen is building an alpha community of degens along with 3,333 fully animated PFP's to match. We are focused on providing all of the benefits that a true degen would need, including top tier alpha from a number of well-respected projects, high quality tools from the most established tools in the space, access to the best WL giveaways with high allocations, and networking facilities to allow holders to connect with each other and find work in web3. We will continue to add more and more value for our holders by keeping up to date with all the latest tools and creating revenue streams through agency work, to contribute towards future development of the Planet Degen ecosystem. More details TBA, this is just the beginning!</h4>
        </div>
        
      </div>
  )
}



