import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import '../assets/styles/Home.css'
import { PlanetNavbar } from '../assets/components/PlanetNavbar'
import { InfoContainer } from '../assets/components/InfoContainer'
import { Faq } from '../assets/components/Faq'
import { LoreContainer } from '../assets/components/LoreContainer'
import { TeamContainer } from '../assets/components/TeamContainer'
import { UtilityContainer } from '../assets/components/UtilityContainer'

import backgroundVideo from '../assets/video/BG2.mp4'
import planet from '../assets/images/PLANET.png'
import title from '../assets/images/TITLE.png'
import crystal1 from '../assets/images/crystal-passive.png'
import crystal2 from '../assets/images/crystal-active.png'
import degens from '../assets/gif/SPLASHART.gif'
import mintHeader from '../assets/gif/TOUCH_THE_CRYSTAL.gif'
import WLsuccess from '../assets/gif/Found.gif'
import WLerror from '../assets/gif/notFound.gif'
import logo from '../assets/images/logo.png'
import whitelist from '../assets/gif/WALLETCHECKER.gif'
import whitelistActive from '../assets/gif/WALLETCHECKERACTIVE.gif'
import meteors from '../assets/gif/meteors.gif'
import {  configureChains, useAccount, usePrepareContractWrite, useContractWrite, useContractRead  } from 'wagmi'
import useDbInfo from "../assets/components/useDbInfo"


export const Home = () => {

  const { address, connector, isConnected } = useAccount()
  const listData = useDbInfo(address);
  const [isWhitelisted, setWLStatus] = useState();
  const [isOGlisted, setOGStatus] = useState();
  const [WhiteListSig, setWLSig] = useState();
  const [OGListSig, setOGSig] = useState();


  const [wlChecked, setWLChecked] = useState(false);

  const navigate = useNavigate();

  const handleImageClick = () => {
    
    navigate("/mint");
  };


  const [overCrystal, setOverCrystal] = useState(false);
  const [overWL, setOverWL] = useState(false);

  useEffect(() => {
    if (!isConnected) {
      return;
    }
    
    setWLStatus(listData[0])
    setOGStatus(listData[1])
    setWLSig(listData[2])
    setOGSig(listData[3])
  }, []);


  const displayListStatus = () => {

    setWLChecked(true)

    if(listData[1] === true){
      alert("You are on the OG list!")
      
    }else if(listData[0] === true){
      alert("You are on the Whitelist!")
    }else if(!isConnected) {
      alert("Please connect your wallet.")
    }
    else{
      alert("Sorry, you are not on either list.")
  };
}
  //listData[1] = OG status  listData[0] = WL status
  return (
    <div className='main-container'>
      <div className='home-container'>
        <div className='video-container'>
            <video autoPlay loop muted playsInline id='video'>
              <source src={backgroundVideo} type='video/mp4' />
            </video>  
        </div>  
        <PlanetNavbar />
        <div className='whitelist-checker'>
          <a href='https://opensea.io/collection/planetdegennft' target="_blank" rel=" noopener noreferrer">
          <img className='whitelist-checker-img' alt='whitelist checker' 
                onMouseOver={() => setOverWL(true)}
                onMouseOut={() => setOverWL(false)} 
                // src={(overWL && !wlChecked) ? whitelistActive : listData[1] ? WLsuccess : listData[0] ? WLsuccess : (wlChecked && !listData[1] && isConnected) || (wlChecked && !listData[0] && isConnected) ? WLerror : whitelist} 
                src={overWL ? whitelistActive : whitelist}
                // onClick={displayListStatus}
                />
            </a>
            <h1 className='wl-tracker-text' >OPENSEA</h1>
        </div>
        <div className='meteor-container'>
          <img src={meteors} alt='meteors' />
        </div>
        <img data-aos="fade-down" className='logo' src={logo} alt='logo'/>
        <div className='container header-images'
        >
          {/* <img data-aos="fade-down" className='mint-header' src={mintHeader} alt='mint header' /> */} 
          <img data-aos="fade-left" className='planet-title' src={title} alt='title'/>
          <img data-aos="fade-right" alt='mint'
               onMouseOver={() => setOverCrystal(true)}
               onMouseOut={() => setOverCrystal(false)}
              //  onClick={handleImageClick} 
               className='crystal' 
               src={overCrystal ? crystal2 : crystal1} />
          <img data-aos="fade-left" className='planet' src={planet} alt='planet'/>
          <img data-aos="fade-right" className='degens' src={degens} alt='degens'/>
        </div>
        <div className= 'mobile-spacing'></div>
        <div className='home-body'>
          <InfoContainer  name='info' id='info'/>
          <LoreContainer  name='lore' id='lore'/>
          <UtilityContainer name='utility' id='utility'/>
          <TeamContainer  name='team' id='team'/>
          <Faq name='faq' id='faq'/>
        </div>
        
        
      </div>
    </div>

  )
} 