import React from 'react'
import '../styles/components.css'
import util from '../images/UTIL.png'
import AOS from 'aos';
import { useEffect } from 'react';

export const UtilityContainer = () => {

  useEffect(() => {
    AOS.init({
      duration : 2000
    });
  }, []);

  return (
      <div   className='info-container container-fluid' data-aos="fade-up">
        <div data-aos="fade-down" className='col bot-container' style={{padding: '0'}}>
          <img data-aos="fade-down" alt='mint' className='bot' src={util} />
        </div>
        <div className='col info-section'>
        <h1 className='overview-header' >Utility</h1>
        <h4 className='utility'>⦾ Lifetime Access to Magically/AlphaSharks for analytics <br></br><br></br>
            ⦾ Full access to Eversify - Minting Bot, Node, Auto sniper <br></br><br></br>
            ⦾ Full access to Floor App to track your wallets & NFT floor prices from your mobile <br></br><br></br>
            ⦾ Top-Tier WL Collaborations with high quantity allocations <br></br><br></br>
            ⦾ In depth analysis and alpha calling from some of the best callers in the space <br></br><br></br>
            ⦾ Networking channels for vetted CM’s and founders <br></br><br></br>
            ⦾ Job listings from Rethinkable <br></br><br></br>
            ⦾ Fully animated collection  <br></br><br></br>
            ⦾ Art integration partnerships to infuse various strong communities on a deeper level</h4>
        </div>
        
      </div>
  )
}



