import styled from "styled-components";
import '../styles/components.css'
import React from 'react'

export const LoreSlides = (props) => {
    return (
    <>
        {/* <h5 className= 'lore-title'>{props.title}</h5> */}
        <p className= 'lore-text' >{props.text}</p>
    </> 
    )
};

export const StyledSlider = styled.div`
  position: relative;
  text-align: left;
  height: 100%;
  padding: 2rem;
  
`;